import { lazy } from "react";

export const lazyWithRetry = (componentImport: any, name: string = "") =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem(`page-${name}-has-been-force-refreshed`) ||
        "false"
    );

    try {
      const component = await componentImport();

      window.localStorage.setItem(
        `page-${name}-has-been-force-refreshed`,
        "false"
      );

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        window.localStorage.setItem(
          `page-${name}-has-been-force-refreshed`,
          "true"
        );
        return window.location.reload();
      }

      throw error;
    }
  });
