import React, { ChangeEvent, KeyboardEvent } from "react";

import ctl from "@netlify/classnames-template-literals";
import SVGIcon from "./SVGIcon";
import RequiredIcon from "./RequiredIcon";
import isEmpty from "lodash/isEmpty";

interface IInputField {
  label?: string;
  errorMessage?: string | null;
  placeholder?: string;
  value?: string | null;
  onChange?: (val: string) => void;
  onBlur?: (val: string) => void;
  onClick?: () => void;
  onTab?: () => void;
  onShiftTab?: () => void;
  onArrowDown?: () => void;
  customClass?: string;
  widthWrapperClass?: string;
  isSearch?: boolean;
  isRequired?: boolean;
  type?: string;
  tabIndex?: number;
  id?: string;
  maximumLength?: number;
  disabled?: boolean;
  size?: string;
  insideElement?: JSX.Element | React.ReactNode;
  currentIndex?: number | null;
  isHorizontal?: boolean;
  labelCustomClass?: string;
  labelTextCustomClass?: string;
  showErrorMessage?: boolean;
}

const InputField = React.memo(
  ({
    label,
    errorMessage,
    placeholder = "Please Input",
    value = "",
    onChange,
    onBlur,
    onClick,
    onTab,
    onShiftTab,
    onArrowDown,
    customClass,
    isSearch = false,
    widthWrapperClass,
    isRequired,
    type = "text",
    tabIndex = -1,
    id,
    maximumLength,
    disabled,
    size = "medium",
    insideElement,
    currentIndex = null,
    isHorizontal = false,
    labelCustomClass = "",
    labelTextCustomClass = "",
    showErrorMessage = true,
  }: IInputField) => {
    const currentId = currentIndex !== null ? `${id}+${currentIndex}` : id;
    const isMediumField = size === "medium";
    const isError =
      (errorMessage && showErrorMessage) ||
      (value && maximumLength && value?.length > maximumLength);
    const inputCN = ctl(`
      input-field
      typography-body text-cn-600
      border border-solid rounded-[0.25rem]
      ${
        disabled
          ? "bg-n-100 border-transparent"
          : `bg-n-000 
            ${
              isError
                ? "border-cd-600"
                : `border-cn-300 hover:border-cp-500
                  active:border-cp-600 focus:border-cp-600 focus:outline-0`
            }
          `
      }
      ${isMediumField ? "py-[0.5rem]" : "py-0 "}
      ${widthWrapperClass && "w-full"}
      ${
        isSearch
          ? isMediumField
            ? "pl-[2rem] pr-[1.75rem]"
            : "pl-[1.25rem] pr-[0.25rem]"
          : "px-[0.625rem]"
      }
      ${customClass}
    `);

    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      e.stopPropagation();
      if (e?.key === "Tab") {
        e.preventDefault();
        if (e?.shiftKey) {
          onShiftTab && onShiftTab();
        } else {
          onTab && onTab();
        }
      }
      if (e?.key === "Enter") {
        e.preventDefault();
        onClick && onClick();
      }
      if (e?.key === "ArrowDown" && onArrowDown) onArrowDown();
    };

    const _onBlur = (e: ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();

      if (!onBlur) return;
      const val = e?.target?.value;
      if (type === "number" && (+val < 0 || val === "")) return;

      onBlur(val);
    };

    const _onChange = (e: ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();

      if (!onChange) return;
      const val = e?.target?.value;
      if (type === "number" && (+val < 0 || val === "")) return;
      onChange(val);
    };

    const labelCN = ctl(`
      text-cn-600 
      ${!labelTextCustomClass?.includes("typography") && "typography-caption"}
      ${labelTextCustomClass}
    `);

    const wrapperCN = ctl(`
      ${widthWrapperClass || ""}
      ${isHorizontal && "flex"}
      ${isHorizontal && label && "gap-[0.75rem]"}
    `);

    const wrapperLabelCN = ctl(`
      flex items-center gap-[0.25rem] 
      ${isHorizontal ? "h-[2.625rem]" : "h-fit mb-[0.25rem]"}
      ${isHorizontal && !labelCustomClass && "w-[7.25rem]"}
      ${labelCustomClass}
    `);

    const searchIconCN = ctl(`{
      mr-[0.5rem] absolute 
      ${isMediumField && "left-[0.625rem]"}
      ${!isMediumField && "left-[0.25rem]"}
    `);

    return (
      <div
        className={wrapperCN}
        onClick={(e) => {
          e?.preventDefault();
          onClick && onClick();
        }}
      >
        {label && (
          <div className={wrapperLabelCN}>
            <p className={labelCN} data-testid={`${currentId}-label`}>
              {label}
              {isRequired && <RequiredIcon id={id} />}
            </p>
          </div>
        )}

        <div className={widthWrapperClass ? "w-full" : ""}>
          <div className="flex items-center relative">
            {isSearch && (
              <SVGIcon
                iconName="icon-search"
                size={16}
                fillColor="var(--n-200)"
                customClass={searchIconCN}
              />
            )}

            {insideElement && insideElement}
            <input
              className={inputCN}
              onChange={(e: ChangeEvent<HTMLInputElement>) => _onChange(e)}
              onBlur={(e: ChangeEvent<HTMLInputElement>) => _onBlur(e)}
              onKeyDown={(event: KeyboardEvent<HTMLInputElement>) =>
                onKeyDown(event)
              }
              value={value || ""}
              placeholder={placeholder}
              type={type}
              tabIndex={tabIndex}
              id={currentId}
              data-testid={currentId}
              disabled={disabled}
            />
            {isSearch && isMediumField && !isEmpty(value) && (
              <SVGIcon
                iconName="icon-cancel"
                size={16}
                fillColor={isEmpty(value) ? "var(--n-200)" : "var(--n-300)"}
                customClass={`mr-[0.5rem] absolute right-[0.25rem] top-[0.825rem] ${
                  isEmpty(value) ? "cursor-not-allowed" : "cursor-pointer"
                }`}
                onClick={() => onChange && onChange("")}
                disabled={isEmpty(value)}
              />
            )}
          </div>

          <div
            className={`flex gap-[0.25rem] ${
              errorMessage && showErrorMessage
                ? "justify-between"
                : "justify-end"
            }`}
          >
            {errorMessage && showErrorMessage && (
              <p
                className="typography-caption text-r-400 mt-[0.25rem]"
                data-testid={`${currentId}-error-message`}
              >
                {errorMessage}
              </p>
            )}

            {maximumLength && (
              <p
                className="typography-caption-bold text-cn-600 mt-[0.25rem]"
                data-testid={`${currentId}-max-length`}
              >
                {!!value ? value?.length : 0}/{maximumLength}
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default InputField;
