const ErrorFallback = () => {
  return (
    <div className="flex flex-col items-center w-[100vw] h-[100vh] justify-center">
      <img
        src="/assets/image/not-found.png"
        alt="not found"
        className="w-[20rem]"
      />
      <p className={`mt-[2.5rem] text-n-700 typography-h2-bold`}>
        Something went wrong.
      </p>
      <p className={`mt-[0.5rem] text-n-400 typography-subtitle`}>
        Please report the issue detail to admin.
      </p>
    </div>
  );
};

export default ErrorFallback;
