import SearchDropdown from "../../../design-system/SearchDropdown";
import { ISelectedDropdown } from "../../../design-system/SearchDropdown";

interface ICountrySearchDropdown {
  selected?: ISelectedDropdown | undefined | null;
  onChange?: (val: ISelectedDropdown | undefined) => void;
  widthClass?: string;
  [propName: string]: any;
}

const CountrySearchDropdown = ({
  selected,
  onChange = () => {},
  widthClass,
  ...props
}: ICountrySearchDropdown) => {
  return (
    <SearchDropdown
      label="Country"
      placeholder="Country"
      fetchData={{
        searchUrl: "api/v1/country",
        params: {
          from: "2024-04-01",
          to: "2024-12-31",
          sortBy: "name",
          sortDir: "ASC",
        },
      }}
      keyItem="country"
      maxOption={null}
      selected={selected}
      onChange={onChange}
      widthClass={widthClass || "w-[24.75rem]"}
      id={props?.id || "country-search-dropdown"}
      {...props}
    />
  );
};

export default CountrySearchDropdown;
