import React, { useState, KeyboardEvent, useEffect, useCallback } from "react";

import ctl from "@netlify/classnames-template-literals";

import useClickOutside from "../hooks/useClickOutside";
import SVGIcon from "../design-system/SVGIcon";

import {
  IContext,
  IInlineDialog,
  IMenuItem,
  IMenuItems,
  ITrigger,
} from "./interface/InlineDialogInterface";
import FloatingComponent from "./FloatingComponent";
import TooltipContainer from "./TooltipContainer";
import {
  focusOnElement,
  onKeyPressDropdownTrigger,
} from "../helper/keyboardAccessHelper";
import { pxToRem } from "../helper/generalUtils";
import { isEqual } from "lodash";

const DialogContext = React.createContext<IContext | null>(null);
DialogContext.displayName = "DialogContext";

export const useInlineDialog = () => React.useContext(DialogContext);

function InlineDialog({
  children,
  customClass = "",
  isBlock,
  withPosition = true,
  useHover = false,
  onTab,
  onShiftTab,
  isOpen,
  setIsOpen,
  usePortal = false,
  useBgColor = false,
  id,
}: IInlineDialog) {
  const [open, setOpen] = useState(false);
  const [isForcedSelect, setIsForcedSelect] = useState(false);

  const ref = React.useRef<HTMLHeadingElement>(null);

  useClickOutside(ref, () => {
    setOpen && setOpen(false);
  });

  const contextValue = {
    open,
    setOpen: () => setOpen(!open),
    isForcedSelect,
    setIsForcedSelect,
    onTab,
    onShiftTab,
    usePortal,
    useBgColor,
    ref,
    id,
  };

  const dialogCN = ctl(`
    ${customClass ? customClass : ""}
    ${withPosition ? "relative" : ""}
    ${isBlock ? "block" : "inline-block"} text-left
  `);

  const onHover = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (!useHover) return;
    setOpen && setOpen(!open);
  };

  useEffect(() => {
    setIsOpen && setIsOpen(open);
  }, [open]);

  useEffect(() => {
    if (isOpen === undefined) return;
    if (isEqual(isOpen, open)) return;
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <DialogContext.Provider value={contextValue}>
      <div
        ref={ref}
        className={dialogCN}
        onMouseEnter={onHover}
        onMouseLeave={onHover}
        data-testid={id}
      >
        {children}
      </div>
    </DialogContext.Provider>
  );
}

export const useDialog = () => React.useContext(DialogContext);

const DEFAULT_MAX_HEIGHT = 12;

const MenuItemsWrapper = ({ ...props }: any) => {
  const context = useDialog();
  const { open: rootOpen, usePortal } = context || {};

  // eslint-disable-next-line no-restricted-globals
  const isOpen = props?.open ?? rootOpen;

  if (!isOpen) return null;

  return (
    <div className="relative">
      {usePortal ? (
        <FloatingComponent
          widthBasedOnChildren={props?.widthBasedOnChildren}
          customContentHeight={
            props?.useDefaultHeight ? DEFAULT_MAX_HEIGHT * 16 : undefined
          }
        >
          <MenuItemContent {...props} />
        </FloatingComponent>
      ) : (
        <MenuItemContent {...props} />
      )}
    </div>
  );
};

function MenuItemContent({
  children,
  position = "right",
  vPosition = "bottom",
  options,
  customClass = "",
  maxHeightWrapper = DEFAULT_MAX_HEIGHT,
  minHeightWrapper,
  flexDirectionClass = "",
  widthBasedOnChildren = false,
}: IMenuItems) {
  const localRef = React.useRef<HTMLHeadingElement>(null);

  const MenuCN = ({
    position,
    vPosition,
    customClass,
  }: {
    position?: "left" | "middle" | "right";
    vPosition?: "top" | "bottom" | "relative" | "relative-to-bottom";
    customClass: string;
  }) => {
    return ctl(`
      absolute
      z-10
      mt-[0.125rem]
      origin-top-right
      items-center
      rounded-[0.25rem]
      bg-n-000
      shadow-raised
      ${customClass && customClass}
      ${vPosition === "relative" && "top-0"}
      ${vPosition === "relative-to-bottom" && "bottom-0"}
      ${vPosition === "top" && "top-[-1.5rem] -translate-y-full"}
      ${vPosition === "bottom" && "bottom-0 translate-y-full"}
      ${position === "left" && "left-0"}
      ${position === "right" && "right-0"}
      ${position === "middle" && "left-0 -translate-x-1/2"}
      ${widthBasedOnChildren && "w-fit min-w-fit"}
      `);
  };

  return (
    <div
      className={MenuCN({ position, vPosition, customClass })}
      ref={localRef}
    >
      <div
        id="inline-dialog-wrapper"
        className={`overflow-x-hidden py-[1px] overflow-y-auto flex flex-wrap w-full ${flexDirectionClass}`}
        style={{
          maxHeight: `${maxHeightWrapper}rem`,
          minHeight: `${minHeightWrapper}rem`,
        }}
        data-testid="options-container"
      >
        {options
          ? options?.map(({ ...props }, index) => (
              <MenuItem
                {...props}
                key={index}
                index={index}
                data-testid={`option-${index}`}
              />
            ))
          : children}
      </div>
    </div>
  );
}

function MenuItem({
  children,
  selected = false,
  onClick,
  customClass = "",
  disabled = false,
  index = 0,
  useHover = true,
  widthBasedOnChildren = false,
}: IMenuItem) {
  const isDisabled = disabled && !selected;
  const { useBgColor } = useDialog() || {};

  const MenuItemCN = (customClass: string) => {
    return ctl(`
      menu-item
      flex 
      items-center
      justify-between
      ${isDisabled ? "cursor-not-allowed" : "cursor-pointer"}
      ${useHover && (isDisabled ? "bg-n-001" : "hover:bg-b-100")}
      ${customClass}
      ${
        selected
          ? "bg-b-100"
          : useBgColor
          ? index % 2 === 0
            ? "bg-n-002"
            : "bg-n-000"
          : ""
      }
      focus-visible:bg-b-100
      w-full
    `);
  };

  const { id, setOpen } = useDialog() || {};

  const _onClick = (e: React.MouseEvent<HTMLElement>) => {
    e?.stopPropagation();
    onClick && !isDisabled && onClick(e);
    setOpen && setOpen();
    id && focusOnElement(id);
  };

  return (
    <li
      onClick={_onClick}
      className={MenuItemCN(customClass)}
      id={`inline-dialog-item-${index}`}
      data-testid={`inline-dialog-item-${index}`}
      tabIndex={index}
    >
      <div
        className="flex w-full items-center justify-between"
        data-cy="inline-dialog-item"
      >
        {children && children}
      </div>
    </li>
  );
}

function Trigger({
  children,
  disabled,
  dataCy,
  customClass,
  customTextClass,
  useArrow,
  onClick,
  tabIndex = -1,
  disabledText,
  unit,
}: ITrigger) {
  const [maxWidth, setMaxWidth] = useState<string>("");
  const [unitRect, setUnitRect] = useState<DOMRect>();

  const handleUnitRect = useCallback((node: HTMLDivElement) => {
    setUnitRect(node?.getBoundingClientRect());
  }, []);

  const {
    open,
    setOpen,
    isForcedSelect,
    setIsForcedSelect,
    onTab,
    onShiftTab,
    id,
  } = useDialog() || {};

  const triggerCN = ctl(`
    relative
    focus-visible:border focus-visible:border-solid focus-visible:border-cp-600 focus-visible:outline-0
    ${disabled ? "bg-n-100 cursor-not-allowed" : "bg-n-000 cursor-pointer"}
    ${open ? "!border-cp-600 !outline-0" : ""}
    ${customClass}
  `);

  const triggerDropdown = (isKeyDown = false) => {
    setIsForcedSelect && setIsForcedSelect(isKeyDown);
    onClick && onClick();
    setOpen && setOpen();
  };

  const _onClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (!disabled) {
      triggerDropdown();
    }
  };

  const onKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (tabIndex !== -1) return;
    onKeyPressDropdownTrigger(
      event,
      id ?? "",
      open ?? false,
      () => triggerDropdown(true),
      () => triggerDropdown(true),
      disabled ?? false,
      {
        onArrowDown: () => {
          if (isForcedSelect) return;
          setIsForcedSelect && setIsForcedSelect(true);
        },
        ...(onTab ? { onTab } : {}),
        ...(onShiftTab ? { onShiftTab } : {}),
      }
    );
  };

  useEffect(() => {
    if (!unit) return;
    const newMaxWidth = `calc(100% - ${
      unitRect?.width ? pxToRem(unitRect?.width) : 0
    }rem)`;
    setMaxWidth(newMaxWidth);
  }, [unitRect]);

  return (
    <div
      className={triggerCN}
      onClick={_onClick}
      onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => onKeyDown(event)}
      tabIndex={tabIndex ?? -1}
      data-cy={dataCy}
      id={id}
      data-testid={`${id}-trigger`}
      // @ts-ignore
      disabled={disabled}
    >
      <TooltipContainer
        show={!!disabled}
        text={disabledText}
        customClass="flex items-center max-w-full"
      >
        <div
          className={`flex gap-[0.5rem] items-center w-full h-full justify-between ${customTextClass}`}
          style={unit ? { maxWidth } : {}}
        >
          {children}

          {useArrow && (
            <SVGIcon
              iconName={open ? "icon-arrow-up" : "icon-arrow-down"}
              size={16}
              fillColor="var(--cn-400)"
            />
          )}
        </div>
        {unit && (
          <div
            className="typography-subtitle text-cn-500 bg-cn-050 px-[0.5rem] py-[0.5625rem] rounded-r-[0.25rem]"
            ref={handleUnitRect}
            id={`${id}-unit`}
          >
            <p>{unit}</p>
          </div>
        )}
      </TooltipContainer>
    </div>
  );
}

InlineDialog.displayName = "InlineDialog";

InlineDialog.Trigger = Trigger;
InlineDialog.MenuItem = MenuItem;
InlineDialog.MenuItems = MenuItemsWrapper;

export default InlineDialog;
