import axios from "axios";
import qs from "qs";
import isEmpty from "lodash/isEmpty";

import { toSnakeCase, toCamelCase } from "./useCaseConverter";

const Axios = axios.create();

const responseHandler = (response: any) => {
  // this is default
  return response;
};

export const setInterceptor = (
  errorHandler: ((error: any) => any) | null | undefined
) => {
  Axios.interceptors.response.use(responseHandler, errorHandler);
};

const FALLBACK_URL = window.location.origin?.includes("staging")
  ? "https://staging-api.provenantcloud.com"
  : "https://api.provenantcloud.com";
const URL = process.env.REACT_APP_API_HOST || FALLBACK_URL;

const apiClient = (
  endpoint: string,
  method: string,
  {
    body,
    params,
    skipNulls = true,
    isSnakeCase = true,
    isShowErrorToast = true,
  }: any,
  contentType = "application/json"
) => {
  const userToken = localStorage.getItem("userToken");

  let config = {
    url: `${URL}/${endpoint}`,
    method: method,
    withCredentials: true,
    headers: {
      "Content-Type": contentType,
      Accept: "application/json",
      Authorization: "Bearer " + userToken,
    },
    ...(body && { data: isSnakeCase ? toSnakeCase(body) : body }),
  };

  if (!isEmpty(params)) {
    params = toSnakeCase(params);
    const convertedParams = qs.stringify(params, {
      arrayFormat: "brackets",
      encode: true,
      skipNulls: skipNulls,
    });

    if (convertedParams) {
      config.url = config.url + "?" + convertedParams;
    }
  }

  const onSuccess = (r: any) => {
    let data = r.data.data != null ? r.data.data : r.data.properties;
    data = isSnakeCase ? toCamelCase(data) : data;

    const totalPage = r?.data?.totalPage ?? 0;

    return {
      isSuccess: true,
      error: null,
      data,
      totalPage,
    };
  };

  const onError = (error: any) => {
    if (error?.response?.status === 401 && !endpoint?.includes("login")) {
      error?.showErrorToast &&
        error?.showErrorToast("Your session is invalid. Please login again.");
      localStorage.removeItem("userId");
      localStorage.removeItem("production-order");
      localStorage.removeItem("subMenu");
      localStorage.removeItem("userToken");
      window.location.replace("/login");
    }

    if (
      isShowErrorToast &&
      error?.showErrorToast &&
      !endpoint?.includes("login")
    ) {
      error?.showErrorToast();
    }

    return {
      isSuccess: false,
      data: null,
      pagination: null,
      totalPage: 0,
      error:
        error?.response?.data?.message?.message ||
        error?.response?.data?.message,
    };
  };

  return Axios(config).then(onSuccess).catch(onError);
};

export default apiClient;
