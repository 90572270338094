import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";

import isEmpty from "lodash/isEmpty";

import useUser from "../../hooks/useUser";
import InputField from "../../design-system/InputField";
import Checkbox from "../../design-system/Checkbox";
import Button from "../../design-system/Button";
import SVGIcon from "../../design-system/SVGIcon";
import SignWrapper from "./SignWrapper";

import apiClient from "../../hooks/apiClient";
import { isWrongFormatEmail } from "../../helper/formHelper";
import { tabProps, focusOnElement } from "../../helper/keyboardAccessHelper";
import { cloneDeep } from "lodash";

const WRONG_LOGIN = "Wrong password or email please check again";

const Login = () => {
  const history = useHistory();
  const { state } = useLocation();
  //@ts-ignore
  const { isFromResetPassword } = state || {};

  const setUserId = useUser((state) => state.setUserId);

  const [loginData, setLoginData] = useState({ email: "", password: "" });
  const [isRememberMe, setIsRememberMe] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [error, setError] = useState<{
    email: string | null;
    password: string | null;
  }>({ email: null, password: null });

  const setRemember = () => {
    isRememberMe &&
      localStorage.setItem(
        "login_data",
        JSON.stringify({
          email: loginData?.email,
        })
      );
    const _loginData = localStorage.getItem("login_data");
    !isRememberMe && _loginData && localStorage.removeItem("login_data");
  };

  const login = async () => {
    setIsLoading(true);
    const { isSuccess, data } = await apiClient("api/v1/user/login", "POST", {
      body: {
        email: loginData?.email,
        password: loginData?.password,
        remember: isRememberMe,
      },
    });
    setIsLoading(false);

    if (isSuccess) {
      setRemember();
      localStorage.setItem("userId", data?.employeeId);
      localStorage.setItem("userToken", data?.token);
      setUserId(data?.employeeId);
      history.replace("/dashboard");
      return;
    }

    setError({
      ...error,
      password: WRONG_LOGIN,
    });
  };

  useEffect(() => {
    return () => {
      isFromResetPassword &&
        history.replace({
          state: {
            isFromResetPassword: false,
          },
        });
    };
  }, [isFromResetPassword]);

  useEffect(() => {
    const _loginData = localStorage.getItem("login_data")
      ? JSON.parse(localStorage.getItem("login_data") || "")
      : null;
    if (_loginData) {
      setLoginData(_loginData);
      setIsRememberMe(true);
    }
    focusOnElement("email");
  }, []);

  const checkEmail = (cloneData: Record<string, string>, key: string) => {
    if (cloneData?.email?.length === 0) return "This field is required";
    if (isWrongFormatEmail(cloneData?.email))
      return "Oops..wrong format, please check again";
    return null;
  };

  const checkPassword = (cloneData: Record<string, string>, key: string) => {
    if (cloneData?.password?.length === 0) return "This field is required";
    return null;
  };

  const checkError = (
    cloneData: Record<string, string>,
    key: string,
    cloneError: Record<string, string | null>
  ) => {
    if (key === "email") {
      const _errorEmail = checkEmail(cloneData, key);
      cloneError.email = _errorEmail;

      if (cloneData?.password?.length > 0) {
        const _errorPassword = checkPassword(cloneData, key);
        cloneError.password = _errorPassword;
      }
    }

    if (key === "password") {
      const _errorPassword = checkPassword(cloneData, key);
      cloneError.password = _errorPassword;
    }

    return cloneError;
  };

  const onChangeData = (key: "email" | "password", value: string) => {
    const value_ = key === "password" ? value?.trim() : value;
    const cloneData = cloneDeep(loginData);
    let cloneError = cloneDeep<any>(error);

    cloneData[key] = value_;
    setLoginData(cloneData);

    cloneError = checkError(cloneData, key, cloneError);
    if (cloneError?.password && key === "password") {
      cloneError.password = !cloneData?.password
        ? "This field is required"
        : null;
    }

    if (cloneError?.email && key === "password") {
      cloneError.password = !cloneData?.password
        ? "This field is required"
        : null;
    }

    setError(cloneError);
  };

  const checkDisabledButton = () =>
    isEmpty(loginData?.email) ||
    isEmpty(loginData?.password) ||
    !isEmpty(error?.email) ||
    !isEmpty(error?.password);

  return (
    <SignWrapper>
      <>
        <p className="typography-title-bold text-n-700">Let’s Get Started</p>
        {isFromResetPassword && (
          <div className="rounded-[0.625rem] bg-b-200 border border-solid border-b-400 w-full flex items-center p-[1rem]">
            <SVGIcon
              iconName="icon-check"
              size={32}
              fillColor="var(--g-400)"
              customClass="mr-[1rem]"
            />
            <p className="typography-subtitle-bold text-n-000">
              Your password has been changed
            </p>
          </div>
        )}

        <div className="flex flex-col gap-[0.25rem]">
          <p className="typography-body-bold text-n-700">Email</p>
          <InputField
            value={loginData?.email}
            onChange={(val: string) => onChangeData("email", val)}
            onEnter={login}
            placeholder="johndoe@email.com"
            customClass="w-full"
            errorMessage={
              error?.password === WRONG_LOGIN ? error?.password : error?.email
            }
            showErrorMessage={error?.password !== WRONG_LOGIN}
            id="email"
            {...tabProps("sign-up-button", "password")}
          />
        </div>
        <div className="flex flex-col gap-[0.25rem] w-[32.125rem]">
          <p className="typography-body-bold text-n-700">Password</p>
          <InputField
            value={loginData?.password}
            onChange={(val: string) => onChangeData("password", val)}
            onEnter={login}
            placeholder="Password"
            errorMessage={error?.password}
            type={showPassword ? "text" : "password"}
            customClass="pr-[2.5rem] pl-[0.75rem] w-full"
            insideElement={
              <SVGIcon
                iconName={showPassword ? "icon-eye" : "icon-eye-off"}
                size={16}
                fillColor="var(--n-700)"
                customClass="mr-[0.5rem] absolute right-[0.25rem] top-[0.825rem] cursor-not-allowed"
                onClick={() => setShowPassword(!showPassword)}
                id="icon-eye"
                {...tabProps("password", "forgot-password")}
              />
            }
            id="password"
            {...tabProps("email", "icon-eye")}
          />
        </div>
        <p
          className="typography-body-bold text-b-400 underline cursor-pointer
            w-fit px-[0.25rem] py-[0.0625rem] rounded-[0.25rem]
            focus:border focus:border-solid focus:border-b-400 focus:px-[0.1875rem] focus:py-0"
          onClick={() => history.replace("/forgot-password")}
          onKeyDown={(e) => {
            e.stopPropagation();
            if (e?.key === "Tab") {
              e.preventDefault();
              if (e?.shiftKey) {
                focusOnElement("icon-eye");
              } else {
                focusOnElement("remember-me");
              }
            }
            if (e?.key === " " || e?.key === "Enter") {
              history.replace("/forgot-password");
            }
          }}
          tabIndex={-1}
          id="forgot-password"
        >
          Forgot Password
        </p>
        <div
          className="flex items-center gap-[0.5rem] cursor-pointer"
          onClick={() => setIsRememberMe(!isRememberMe)}
        >
          <Checkbox
            id="remember-me"
            value="remember-me"
            checked={isRememberMe}
            onChange={() => setIsRememberMe(!isRememberMe)}
            {...tabProps("forgot-password", ["login-button", "sign-up-button"])}
          />
          <p className="typography-body-bold text-n-700">Remember Me</p>
        </div>
        <Button
          id="login-button"
          wrapperCustomClass="w-full"
          customClass="w-full h-[3.875rem]"
          onClick={login}
          isLoading={isLoading}
          disabled={checkDisabledButton()}
          {...tabProps("remember-me", "sign-up-button")}
        >
          Sign in
        </Button>
        <Button.Secondary
          id="sign-up-button"
          wrapperCustomClass="w-full"
          customClass="w-full h-[3.875rem]"
          onClick={login}
          {...tabProps(["login-button", "remember-me"], "email")}
        >
          Sign up
        </Button.Secondary>
      </>
    </SignWrapper>
  );
};

export default Login;
