import useToast from "./useToast";

const useNetworkError = () => {
  const setToastData = useToast((state) => state.setToastData);

  const globalHandler = (error: any) => {
    const resultError = {
      ...error,
      showErrorToast: (customMessage?: string) =>
        setToastData({
          show: true,
          warning: true,
          message:
            customMessage ||
            error?.response?.data?.message ||
            error?.message ||
            "Something went wrong! Please try again later.",
        }),
    };

    return Promise.reject(resultError);
  };

  return { globalHandler };
};

export default useNetworkError;
