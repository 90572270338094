import create, { GetState, SetState } from "zustand";

const useMenu = create((set: SetState<any>, get: GetState<any>) => ({
  menuList: [],
  selectedMenu: {},

  setMenuList: (data: string) => set({ menuList: data }),
  setSelectedMenu: (data: string) => set({ selectedMenu: data }),
}));

export default useMenu;
