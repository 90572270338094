import SVGIcon from "./SVGIcon";

const RequiredIcon = ({ id }: { id?: string }) => {
  return (
    // <SVGIcon
    //   iconName="icon-required"
    //   size={8}
    //   id={`${id}-required-icon`}
    // />
    <span className="text-cd-600" data-testid={`${id}-required-icon`}>
      {" "}
      *
    </span>
  );
};

export default RequiredIcon;
