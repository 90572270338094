import create, { GetState, SetState } from "zustand";

const useUser = create((set: SetState<any>, get: GetState<any>) => ({
  userId: null,
  setUserId: (data: string) => set({ userId: data }),
  userData: null,
  setUserData: (data: string) => set({ userData: data }),
  organizationData: null,
  setOrganizationData: (data: Record<string, unknown>) =>
    set({ organizationData: data }),
  relationId: null,
  setRelationId: (data: string) => set({ relationId: data }),
  tenant: null,
  setTenant: (data: string) => set({ tenant: data }),
  isSidebarMax: false,
  setIsSidebarMax: (data: string) => set({ isSidebarMax: data }),
}));

export default useUser;
