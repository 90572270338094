import { KeyboardEvent, ReactNode } from "react";

import ctl from "@netlify/classnames-template-literals";

import "./RadioButton.scss";
import { focusOnElement } from "../helper/keyboardAccessHelper";

const RadioButton = ({
  isActive,
  setIsActive,
  children,
  onTab,
  onShiftTab,
  disabled,
  id = "radio-button",
}: {
  isActive: boolean;
  setIsActive?: (act: boolean) => void;
  children?: ReactNode;
  onTab?: () => void;
  onShiftTab?: () => void;
  disabled?: boolean;
  id?: string;
}) => {
  const WrapperCN = ctl(`
    flex items-center p-[0.25rem] rounded-full
    focus-within:[&:has(:focus-visible)]:border focus-within:[&:has(:focus-visible)]:border-solid
    focus-within:[&:has(:focus-visible)]:border-b-400 focus-within:[&:has(:focus-visible)]:p-[0.1875rem]
  `);

  const RadioCN = ctl(`
    radio 
    w-[1.25rem] h-[1.25rem]
    rounded-full appearance-none
    focus:outline-none 
    ${!isActive && "border border-[0.125rem] border-solid border-n-200"}
    ${disabled ? "cursor-not-allowed" : "cursor-pointer"}
  `);

  const onClick = () => {
    !disabled && setIsActive && setIsActive(!isActive);
    focusOnElement(id || "radio-button");
  };

  const onKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    e.stopPropagation();
    if (e?.key === "Tab") {
      e.preventDefault();
      if (e?.shiftKey) {
        onShiftTab && onShiftTab();
      } else {
        onTab && onTab();
      }
    }
    if (e?.key === " " || e?.key === "Enter") {
      e.preventDefault();
      onClick();
    }
  };

  const focusableProps = {
    tabIndex: -1,
    id: id,
    onKeyDown: onKeyDown,
  };

  return (
    <div className="flex items-center">
      <div className={WrapperCN} onClick={onClick}>
        <input
          checked={isActive}
          type="radio"
          value=""
          name={id || "default-radio"}
          className={RadioCN}
          data-testid={`${id}-radio`}
          {...(isActive ? {} : focusableProps)}
        />
        <label
          htmlFor="radio-button"
          className={disabled ? "cursor-not-allowed" : "cursor-pointer"}
          {...(isActive ? focusableProps : {})}
        />
      </div>
      {children && (
        <label
          htmlFor="radio-button"
          className={disabled ? "cursor-not-allowed" : "cursor-pointer"}
          data-testid={`${id}-label`}
        >
          {children}
        </label>
      )}
    </div>
  );
};

export default RadioButton;
