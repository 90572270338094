import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

import {
  IFloatingComponent,
  IFloatingPortal,
} from "./interface/FloatingComponentInterface";

const FloatingComponent = ({
  children,
  lockScroll = true,
  customContentHeight,
  customClass = "",
  customPortalElement,
  usePortal = true,
  childWrapperClass = "",
  widthBasedOnChildren = false,
}: IFloatingComponent) => {
  const ref = useRef<HTMLDivElement>(null);
  const childRef = useRef<HTMLDivElement>(null);

  const mainbody = document.querySelector<HTMLElement>(".main-body");
  const styledScrollbar =
    document.querySelectorAll<HTMLElement>("#styled-scrollbar");

  const [clientRect, setClientRect] = useState<DOMRect | null>(null);
  const [top, setTop] = useState<number | null>(null);
  const [visible, setVisible] = useState(false);
  const [horizontalAlign, setHorizontalAlign] = useState<string | null>(null);
  const [verticalAlign, setVerticalAlign] = useState<string | null>(null);

  useEffect(() => {
    if (lockScroll) {
      mainbody?.style.setProperty("overflow", "hidden", "important");

      for (let i = 0; i < styledScrollbar?.length; i++) {
        styledScrollbar[i].style.setProperty("overflow", "hidden", "important");
      }
      return () => {
        if (mainbody) mainbody.style.overflow = "";

        for (let i = 0; i < styledScrollbar?.length; i++) {
          styledScrollbar[i].style.overflow = "";
        }
      };
    }
    // eslint-disable-next-line
  }, []);

  const calculatePosition = useCallback(() => {
    const vh = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    );
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );

    const height =
      customContentHeight ||
      childRef?.current?.firstElementChild?.getBoundingClientRect().height ||
      0;
    const width =
      childRef?.current?.firstElementChild?.getBoundingClientRect().width || 0;

    const rect = ref?.current?.getBoundingClientRect();
    setClientRect(rect || null);

    if (!rect) return;

    if (vw < rect?.left + width) {
      setHorizontalAlign("right");
    }
    const isTop = vh < rect?.top + rect?.height + height;
    if (isTop) {
      setVerticalAlign("top");
    } else {
      setVerticalAlign("bottom");
    }

    setTop(customContentHeight && isTop ? rect?.top - 16 : rect?.top);
    setVisible(true);
  }, [childRef, customContentHeight]);

  useEffect(() => {
    if (ref && childRef) {
      calculatePosition();
    }
  }, [ref, childRef, calculatePosition]);

  return (
    <div
      ref={ref}
      className="is-portal pointer-events-none absolute left-0 top-0 h-full w-full"
    >
      {usePortal ? (
        <FloatingPortal
          customClass={customClass}
          customPortalElement={customPortalElement}
          cssStyle={{
            position: "fixed",
            left:
              widthBasedOnChildren && clientRect
                ? clientRect?.left
                : clientRect
                ? clientRect?.left
                : 0,
            zIndex: 1002,
            visibility: visible ? "visible" : "hidden",
            height: clientRect?.height,
            pointerEvents: "none",
            ...(top && { top: top }),
            ...(widthBasedOnChildren
              ? { width: "w-fit", minWidth: clientRect?.width }
              : { width: clientRect?.width }),
          }}
        >
          <div
            className={`pointer-events-auto ${childWrapperClass}`}
            ref={childRef}
          >
            {React.cloneElement(children, {
              ...(horizontalAlign && {
                position: horizontalAlign,
              }),
              ...(verticalAlign && { vPosition: verticalAlign }),
            })}
          </div>
        </FloatingPortal>
      ) : (
        <div
          className={`pointer-events-auto ${childWrapperClass}`}
          ref={childRef}
        >
          {React.cloneElement(children, {
            ...(horizontalAlign && {
              position: horizontalAlign,
            }),
            ...(verticalAlign && { vPosition: verticalAlign }),
          })}
        </div>
      )}
    </div>
  );
};

const FloatingPortal = ({
  children,
  customClass,
  cssStyle,
  customPortalElement,
}: IFloatingPortal) => {
  const sharedDropdown =
    customPortalElement || document.getElementById(`shared-dropdown`);

  return (
    sharedDropdown &&
    ReactDOM.createPortal(
      <div
        id="floating-dropdown"
        className={customClass}
        style={cssStyle}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {children}
      </div>,
      sharedDropdown
    )
  );
};

export default FloatingComponent;
