import { useEffect, useState } from "react";
import apiClient from "../../hooks/apiClient";

import isEmpty from "lodash/isEmpty";

import InputField from "../../design-system/InputField";
import Button from "../../design-system/Button";
import SignWrapper from "./SignWrapper";
import { isWrongFormatEmail } from "../../helper/formHelper";
import { useHistory } from "react-router-dom";
import {
  tabProps,
  focusOnElement,
  tryFocusOnElement,
} from "../../helper/keyboardAccessHelper";

const ForgotPassword = () => {
  const history = useHistory();
  const [loginData, setLoginData] = useState({ email: "" });
  const [error, setError] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const login = async () => {
    if (isWrongFormatEmail(loginData?.email)) {
      setError("Oops..wrong format, please check again");
      return;
    }
    setError("");
    const { isSuccess } = await apiClient("api/v1/password_reset", "POST", {
      body: { email: loginData?.email },
    });
    setIsSuccess(isSuccess);
  };

  useEffect(() => {
    if (isSuccess) return;
    focusOnElement("email");
  }, [isSuccess]);

  return (
    <SignWrapper paddingXClass="px-[2.5rem]">
      {isSuccess ? (
        <div className="flex flex-col gap-[1.25rem]">
          <p className="typography-h2-bold text-n-700">
            The link has been sent!
          </p>
          <p className="typography-subtitle text-n-700">
            We have sent reset password link to{" "}
            <span className="typography-subtitle text-b-400">
              {loginData?.email}
            </span>{" "}
            please check your inbox or junk mail
          </p>
          <Button
            id="confirm-button"
            customClass="w-full h-[3.875rem]"
            onClick={() => history.replace("/login")}
          >
            Confirm
          </Button>
        </div>
      ) : (
        <div className="flex flex-col gap-[1.25rem]">
          <p className="typography-h2-bold text-n-700">Forgot your password</p>
          <p className="typography-subtitle text-n-700">
            Don't worry, we're going to reset your password. Please enter your
            email address, and we'll send you a reset link
          </p>

          <div className="flex flex-col gap-[0.25rem]">
            <p className="typography-body-bold text-n-700">Email</p>
            <InputField
              value={loginData?.email}
              onChange={(val: string) =>
                setLoginData({ ...loginData, email: val })
              }
              placeholder="johndoe@email.com"
              customClass="w-full"
              errorMessage={error}
              id="email"
              {...tabProps("back-to-login-button", "support-email")}
            />
          </div>
          <p className="typography-subtitle text-n-700">
            If you forgot your email please contact our administrator at{" "}
            <a
              className="typography-subtitle text-b-400
                w-fit px-[0.25rem] py-[0.0625rem] rounded-[0.25rem]
                focus:border focus:border-solid focus:border-b-400 focus:px-[0.1875rem] focus:py-0"
              href="mailto:support@provenant.nl"
              onKeyDown={(e) => {
                e.stopPropagation();
                if (e?.key === "Tab") {
                  e.preventDefault();
                  if (e?.shiftKey) {
                    focusOnElement("email");
                  } else {
                    tryFocusOnElement([
                      "send-reset-password-button",
                      "back-to-login-button",
                    ]);
                  }
                }
                if (e?.key === " " || e?.key === "Enter") {
                  document.getElementById("support-email")?.click();
                }
              }}
              tabIndex={-1}
              id="support-email"
            >
              support@provenant.nl
            </a>
          </p>
          <Button
            id="send-reset-password-button"
            wrapperCustomClass="!w-full"
            customClass="w-full h-[3.875rem]"
            onClick={login}
            disabled={isEmpty(loginData?.email)}
            {...tabProps("support-email", "back-to-login-button")}
          >
            Send Reset Password Link
          </Button>
          <Button
            id="back-to-login-button"
            wrapperCustomClass="!w-full"
            customClass="w-full h-[3.875rem]"
            onClick={() => history.replace("/login")}
            {...tabProps(
              ["send-reset-password-button", "support-email"],
              "email"
            )}
          >
            Back to Login
          </Button>
        </div>
      )}
    </SignWrapper>
  );
};

export default ForgotPassword;
