// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.secondary-button svg {
  fill: var(--cp-600);
}

.secondary-button:hover svg {
  fill: var(--cp-500);
}

.secondary-button:disabled svg {
  fill: var(--cn-400);
}`, "",{"version":3,"sources":["webpack://./src/design-system/Button.scss"],"names":[],"mappings":"AACE;EACE,mBAAA;AAAJ;;AAKE;EACE,mBAAA;AAFJ;;AAOE;EACE,mBAAA;AAJJ","sourcesContent":[".secondary-button {\n  svg {\n    fill: var(--cp-600);\n  }\n}\n\n.secondary-button:hover {\n  svg {\n    fill: var(--cp-500);\n  }\n}\n\n.secondary-button:disabled {\n  svg {\n    fill: var(--cn-400);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
