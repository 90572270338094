import FilterDropdown from "../pages/manage/FilterDropdown";

const LocationFilterDropdown = (props: any) => {
  const list = ["Business", "Correspondence", "Others"];

  return (
    <FilterDropdown
      list={list}
      title="Location"
      {...props}
      id={props?.id || "location-filter-dropdown"}
      currentIndex={props?.index}
    />
  );
};

export default LocationFilterDropdown;
