interface IShimmer {
  customClass?: string;
  heightClass?: string;
  widthClass?: string;
}
const Shimmer = ({
  customClass,
  heightClass = "h-[1.5rem]",
  widthClass = "w-full",
}: IShimmer) => {
  return (
    <div
      className={`animate-pulse bg-n-200 rounded-[1.25rem] ${customClass} ${heightClass} ${widthClass}`}
    ></div>
  );
};

export default Shimmer;
