import axios from "axios";
import { isEmpty, isNaN } from "lodash";
import LocationFilterDropdown from "../components/LocationFilterDropdown";
import GroupSearchDropdown from "../pages/manage/search-dropdown/GroupSearchDropdown";
import CountrySearchDropdown from "../pages/manage/search-dropdown/CountrySearchDropdown";
import { tabProps, focusOnElement } from "./keyboardAccessHelper";
import { formatNumber } from "./generalUtils";

export const isWrongFormatEmail = (email: string) => {
  const Regex =
    // eslint-disable-next-line
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !Regex.test(email);
};

const validateField = (field: any, value: string | number | boolean) => {
  const { isRequired, validation, length, maximumLength, minimumLength } =
    field || {};

  if (validation === "toggle") return null;
  const isNumber = typeof value === "number";
  const isString = typeof value === "string";

  const stringifyValue = isNumber ? value?.toString() : value;

  const isEmptyField = isNumber ? isNaN(value) : isEmpty(stringifyValue);

  if (isEmptyField && isRequired) {
    return "This field is required";
  }
  if (
    validation === "email" &&
    isString &&
    !isEmptyField &&
    isWrongFormatEmail(value)
  )
    return `Oops..wrong format, please check again`;
  if (
    !!maximumLength &&
    !!minimumLength &&
    !!value &&
    isString &&
    (value?.length > maximumLength || value?.length < minimumLength)
  )
    return `Please input this field between ${minimumLength} - ${maximumLength} characters`;
  if (!!maximumLength && isString && !!value && value?.length > maximumLength)
    return `Please input this field maximum ${maximumLength} characters`;
  if (!!length && isString && !!value && value?.length < length)
    return `Please input this field minimal ${length} characters`;
  return null;
};

const listFieldLocation = (index?: number, onTabLastElement?: () => void) => [
  {
    key: "locationName",
    label: "Location name",
    isRequired: true,
    maximumLength: 30,
    ...tabProps(
      [`delete-section+${index}`, `see-more+${index}`],
      `type+${index}`
    ),
  },
  {
    key: "type",
    label: "Location Type",
    type: "custom",
    children: <LocationFilterDropdown index={index} />,
    isRequired: true,
    widthClass: "w-[24.875rem]",
    ...tabProps(`locationName+${index}`, `address+${index}`),
  },
  {
    key: "address",
    label: "Address",
    isRequired: true,
    ...tabProps(`type+${index}`, `state+${index}`),
  },
  {
    key: "state",
    label: "State",
    ...tabProps(`address+${index}`, `place+${index}`),
  },
  {
    key: "place",
    label: "City",
    isRequired: true,
    ...tabProps(`state+${index}`, `postcode+${index}`),
  },
  {
    key: "postcode",
    label: "Zip Code",
    isRequired: true,
    maximumLength: 10,
    ...tabProps(`place+${index}`, `country+${index}`),
  },
  {
    key: "country",
    label: "Country",
    isRequired: true,
    type: "custom",
    children: <CountrySearchDropdown />,
    wrapperCustomClass: "w-[24.75rem]",
    id: `country`,
    ...tabProps(`postcode+${index}`, `group+${index}`),
  },
  {
    key: "group",
    label: "Group",
    type: "custom",
    children: <GroupSearchDropdown />,
    widthClass: "w-[24.75rem]",
    additionalParams: { type: "Location" },
    useClearButton: true,
    id: `group`,
    ...tabProps(`country+${index}`, `isDefault+${index}`),
  },
  {
    key: "isDefault",
    type: "checkbox",
    label: "Make this default location",
    id: `isDefault+${index}`,
    onShiftTab: () => focusOnElement(`group+${index}`),
    onTab: () => onTabLastElement && onTabLastElement(),
  },
];

const getCurrencySymbol = (currencyCode: string) => {
  return (0)
    .toLocaleString(navigator.language, {
      style: "currency",
      currency: currencyCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, "")
    .trim();
};

const fieldHalfWidth = "w-[24.75rem]";
const fieldFullWidth = "w-[50.75rem]";

const uploadImage = async (
  val: any,
  uploadUrl: string,
  onChange?: (val: string) => void
) => {
  if (val === "") {
    onChange && onChange("");
    return;
  }
  const formData = new FormData();

  formData.append("image", val);
  const userToken = localStorage.getItem("userToken");
  const options = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + userToken,
    },
  };
  axios.defaults.withCredentials = true;
  const { data } = await axios.post(
    `${process.env.REACT_APP_API_HOST}/${uploadUrl}`,
    formData,
    options
  );
  onChange && onChange(data?.data?.imageUrl);
};

const convertFileSize = (sizeInByte: number, decimalPrecision: number = 2) => {
  const units = ["B", "KB", "MB"];
  let size = sizeInByte;
  let index = 0;
  while (index < units?.length && size >= 1024) {
    size /= 1024;
    index++;
  }
  return `${formatNumber(size?.toFixed(decimalPrecision), {
    suffix: ` ${units[index]}`,
  })}`;
};

export {
  validateField,
  listFieldLocation,
  getCurrencySymbol,
  fieldHalfWidth,
  fieldFullWidth,
  uploadImage,
  convertFileSize,
};
