import SearchDropdown from "../../../design-system/SearchDropdown";
import { ISelectedDropdown } from "../../../design-system/SearchDropdown";

interface IGroupSearchDropdown {
  selected?: ISelectedDropdown | undefined | null;
  onChange?: (val: ISelectedDropdown | undefined) => void;
  customLabel?: string;
  customPlaceholder?: string;
  customId?: string;
  widthClass?: string;
  [propName: string]: any;
}

const GroupSearchDropdown = ({
  selected,
  onChange = () => {},
  customLabel,
  customPlaceholder,
  widthClass,
  customId,
  ...props
}: IGroupSearchDropdown) => {
  return (
    <SearchDropdown
      label={customLabel}
      placeholder={customPlaceholder || "Group"}
      fetchData={{
        searchUrl: "api/v1/group",
        params: {
          isActive: true,
          sortBy: "name",
          sortDir: "ASC",
          limit: 100,
          ...(props?.additionalParams ?? {}),
        },
      }}
      maxOption={null}
      selected={selected}
      onChange={onChange}
      widthClass={widthClass || "w-[24.75rem]"}
      id={customId || "group-search-dropdown"}
      {...props}
    />
  );
};

export default GroupSearchDropdown;
