import { useEffect, useState } from "react";
import FilterDropdown from "../FilterDropdown";
import apiClient from "../../../hooks/apiClient";

const GroupFilterDropdown = (props: any) => {
  const [list, setList] = useState<string[] | []>(["GROUP A"]);
  const [isLoading, setIsLoading] = useState(false);

  const getData = async () => {
    setIsLoading(true);

    const { data } = await apiClient("api/v1/group", "GET", {
      params: {
        from: "2024-04-01",
        to: "2024-12-31",
        isActive: true,
        sortBy: "name",
        sortDir: "ASC",
        ...(props?.additionalParams && props?.additionalParams),
      },
    });
    if (data) {
      setList(data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <FilterDropdown
      list={list}
      title="Group"
      isLoading={isLoading}
      widthBasedOnChildren
      {...props}
      id={props?.id || "group-filter-dropdown"}
      keyList="name"
    />
  );
};

export default GroupFilterDropdown;
