import React, { KeyboardEvent } from "react";

import ctl from "@netlify/classnames-template-literals";
import { pxToRem } from "../helper/generalUtils";

interface SVGIconProps {
  iconName: string;
  id?: string;
  customClass?: string;
  size?: number;
  fillColor?: string;
  onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  onTab?: () => void;
  onShiftTab?: () => void;
  onMouseDown?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  viewBox?: string;
  width?: number;
  height?: number;
  disabled?: boolean;
  useRem?: boolean;
  isFocusable?: boolean;
}

const SVGIcon = ({
  id,
  customClass,
  size,
  iconName,
  fillColor = "var(--p-400)",
  onClick,
  onTab,
  onShiftTab,
  viewBox,
  width,
  height,
  disabled = false,
  onMouseDown,
  useRem = false,
  isFocusable = true,
}: SVGIconProps) => {
  let _width = width || size;
  const _height = height || size;
  const btnCN = ctl(`
    flex items-center justify-center rounded-[0.25rem]
    ${customClass}
    ${
      isFocusable
        ? "focus-visible:border focus-visible:border-solid focus-visible:border-b-400 focus-visible:outline-0"
        : ""
    }
    ${onClick && (disabled ? "cursor-not-allowed" : "cursor-pointer")}
  `);

  const onKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    e.stopPropagation();
    if (e?.key === "Tab") {
      e.preventDefault();
      if (e?.shiftKey) {
        onShiftTab && onShiftTab();
      } else {
        onTab && onTab();
      }
    }
    if (e?.key === " " || e?.key === "Enter") {
      e.preventDefault();
      onClick && onClick();
    }
  };

  return (
    <span
      id={id || "svg-" + iconName}
      data-testid={id || "svg-" + iconName}
      className={btnCN}
      onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
        onClick && !disabled && onClick(e)
      }
      onMouseDown={(e: React.MouseEvent<HTMLButtonElement>) =>
        onMouseDown && !disabled && onMouseDown(e)
      }
      style={{
        height: useRem ? `${_height}rem` : _height && `${pxToRem(_height)}rem`,
        width: useRem ? `${_width}rem` : _width && `${pxToRem(_width)}rem`,
      }}
      tabIndex={-1}
      {...(isFocusable ? { onKeyDown } : {})}
    >
      <svg
        style={{
          height: useRem
            ? `${_height}rem`
            : _height && `${pxToRem(_height)}rem`,
          width: useRem ? `${_width}rem` : _width && `${pxToRem(_width)}rem`,
        }}
        viewBox={viewBox ? viewBox : "0 0 24 24"}
        fill={fillColor}
      >
        <use
          xlinkHref={`/assets/image/general.svg#${iconName}`}
          href={`/assets/image/general.svg#${iconName}`}
        />
      </svg>
    </span>
  );
};

export default SVGIcon;
