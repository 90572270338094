import SVGIcon from "../SVGIcon";
import { kebabCase } from "lodash";
import { IThreeDotsCustomProps } from "../../pages/manage/TableTemplate";
import LoadingSpinner from "../LoadingSpinner";

const tableLimit = [25, 50, 100, 500, 1000];

const tableColumnIdProps = (
  tableId?: string,
  rowIndex?: number,
  columnKey?: string
) => ({
  tableId: tableId,
  rowIndex: rowIndex,
  columnKey: columnKey,
});

const getHeaderKey = (headers: any[], index: number) => {
  const header = headers[index];
  return (
    header?.headerKey ||
    header?.key + (header?.subKey ? `-${header.subKey}` : "") ||
    index
  );
};

const getHeaderColumnId = (tableId: string, headers: any[], index: number) => {
  return `${tableId}+header-column-${getHeaderKey(headers, index)}`;
};

const getColumnId = (
  tableId: string,
  headers: any[],
  rowIndex: number,
  columnIndex: number
) => {
  const key = getHeaderKey(headers, columnIndex);
  return `${tableId}+row-${rowIndex}+column-${key}`;
};

const threeDotsMenuItem = (option: {
  iconName?: string;
  iconSize?: number;
  iconColor?: string;
  title?: string;
  typography?: string;
  textColor?: string;
  onClick?: () => void;
  disabled?: boolean;
  isLoading?: boolean;
}) => {
  const {
    iconName,
    iconSize,
    iconColor,
    title,
    typography,
    textColor,
    onClick,
    disabled,
    isLoading,
  } = option;

  const disabled_ = disabled || isLoading;

  return {
    onClick: onClick,
    disabled: disabled_,
    customComponent: (
      <div
        className="flex items-center gap-[0.625rem] px-[0.75rem] py-[0.375rem] w-full"
        data-testid={kebabCase(title)}
      >
        <SVGIcon
          iconName={iconName || ""}
          size={iconSize || 24}
          fillColor={disabled_ ? "var(--cn-400)" : iconColor || "var(--cn-700)"}
        />
        <span
          className={`w-max ${typography || "typography-subtitle-semibold"} ${
            disabled_ ? "text-cn-400" : textColor || "text-cn-700"
          }`}
          data-testid={`${kebabCase(title)}-text`}
        >
          {title}
        </span>
        {isLoading && (
          <div className="ml-auto">
            <LoadingSpinner size={1} />
          </div>
        )}
      </div>
    ),
  };
};

const defaultThreeDotsMenuItems = (
  options: string[],
  item: any,
  redirectData: (data: any, type: string) => void,
  onClickDelete?: () => void,
  customProps?: IThreeDotsCustomProps
) => {
  const getCustomProp = (option: string, prop: string) => {
    if (!customProps?.[option]) return undefined;
    return (
      //@ts-ignore
      customProps[option]?.[prop] || (prop === "disabled" ? false : undefined)
    );
  };

  return [
    options?.includes("view") &&
      threeDotsMenuItem({
        title: "View",
        onClick: () => redirectData(item, "overview"),
        iconName: getCustomProp("view", "iconName") ?? "icon-view-document",
        iconColor: getCustomProp("view", "iconColor"),
        textColor: getCustomProp("view", "textColor"),
        disabled: getCustomProp("view", "disabled"),
      }),
    options?.includes("edit") &&
      threeDotsMenuItem({
        title: "Edit",
        onClick: () => redirectData(item, "edit"),
        iconName: getCustomProp("edit", "iconName") ?? "icon-edit",
        iconColor: getCustomProp("edit", "iconColor"),
        textColor: getCustomProp("edit", "textColor"),
        disabled: getCustomProp("edit", "disabled"),
      }),
    options?.includes("lock") &&
      threeDotsMenuItem({
        title: "Lock",
        onClick: () => {},
        iconName: getCustomProp("lock", "iconName") ?? "icon-lock",
        iconColor: getCustomProp("lock", "iconColor"),
        textColor: getCustomProp("lock", "textColor"),
        disabled: getCustomProp("lock", "disabled"),
      }),
    options?.includes("delete") &&
      threeDotsMenuItem({
        title: getCustomProp("delete", "title") ?? "Delete",
        onClick: onClickDelete,
        iconName:
          getCustomProp("delete", "iconName") ??
          (getCustomProp("delete", "disabled")
            ? "icon-trash-disabled"
            : "icon-trash"),
        iconColor: getCustomProp("delete", "iconColor"),
        textColor: getCustomProp("delete", "textColor"),
        disabled: getCustomProp("delete", "disabled"),
      }),
  ];
};

export {
  tableLimit,
  tableColumnIdProps,
  getHeaderKey,
  getHeaderColumnId,
  getColumnId,
  threeDotsMenuItem,
  defaultThreeDotsMenuItems,
};
