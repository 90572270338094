import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";

interface TooltipInterface {
  show: boolean;
  portalId: string;
  portalData: any;
  className?: string;
  dataCy?: string;
  tooltipId?: string;
  backgroundClassname?: string;
}

const Tooltip = ({
  show,
  backgroundClassname = "bg-n-700",
  className,
  dataCy,
  portalData,
  tooltipId,
}: TooltipInterface) => {
  return (
    <CSSTransition
      in={show}
      timeout={300}
      classNames="fade"
      unmountOnExit
      appear
    >
      <div
        data-cy={dataCy ? dataCy : "tooltip-portal"}
        id={tooltipId}
        className={`${
          portalData.noWrap ? "tooltip-portal whitespace-nowrap" : ""
        }
          tooltip-avatar pointer-events-none fixed z-9999999 rounded-lg ${backgroundClassname}
          px-[0.5rem] py-[0.25rem]
          text-n-000 z-[20]
          ${className ? className : ""}`}
        style={{
          right: portalData.right,
          left: portalData.left,
          top: portalData.top,
          bottom: portalData.bottom,
          transform: `translate(${portalData?.translateX || 0}, ${
            portalData?.translateY || 0
          }) `,
        }}
      >
        {portalData?.tooltipContent || (
          <p className="typography-body break-word whitespace-normal">
            {portalData.name}
          </p>
        )}

        {portalData.desc && <p>{portalData.desc}</p>}
      </div>
    </CSSTransition>
  );
};

const TooltipPortal = (props: TooltipInterface) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const sharedTooltip = document.getElementById(props.portalId)!;

  return ReactDOM.createPortal(<Tooltip {...props} />, sharedTooltip);
};

export default TooltipPortal;
