import { renderRoutes } from "react-router-config";
import { Switch } from "react-router";
import { BrowserRouter as Router } from "react-router-dom";

import routes from "../Routes";
import React, { useEffect } from "react";
import useUser from "../hooks/useUser";
import isEmpty from "lodash/isEmpty";
import apiClient from "../hooks/apiClient";

const AuthenticatedPage = () => {
  const data = routes();
  const userIdinLocalStorage = localStorage.getItem("userId");

  const setUserData = useUser((state) => state.setUserData);
  const userData = useUser((state) => state.userData);
  const organizationData = useUser((state) => state.organizationData);
  const setOrganizationData = useUser((state) => state.setOrganizationData);

  const getUserData = async () => {
    const { data } = await apiClient(
      `api/v1/user/${userIdinLocalStorage}`,
      "GET",
      {}
    );

    if (data) setUserData(data);
  };

  const getOrganizationData = async () => {
    const { data } = await apiClient(`api/v1/preference`, "GET", {
      params: {
        groupName: "Account",
      },
    });

    if (data) setOrganizationData(data);
  };

  useEffect(() => {
    isEmpty(userData) && getUserData();
    isEmpty(organizationData) && getOrganizationData();
  }, [userIdinLocalStorage]);

  return (
    <React.Suspense fallback={<></>}>
      <Router>
        <Switch>{renderRoutes(data)}</Switch>
      </Router>
    </React.Suspense>
  );
};

export default AuthenticatedPage;
