import { useEffect, Suspense } from "react";
import { shallow } from "zustand/shallow";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ErrorBoundary } from "react-error-boundary";

import useUser from "./hooks/useUser";
import useToast from "./hooks/useToast";

import apiClient, { setInterceptor } from "./hooks/apiClient";

import AuthenticatedPage from "./pages/AuthenticatedPage";
import UnAuthenticatedPage from "./pages/UnAuthenticatedPage";

import ToastMessage from "./components/ToastMessage";

import "./App.css";
import ErrorFallback from "./components/ErrorFallback";
import useNetworkError from "./hooks/useNetworkError";

const queryClient = new QueryClient();

function App() {
  const { globalHandler } = useNetworkError();
  const { toastData, setToastData } = useToast(
    (state) => ({
      toastData: state.toastData,
      setToastData: state.setToastData,
    }),
    shallow
  );
  const userIdinLocalStorage = localStorage.getItem("userId");
  const userId = useUser((state: { userId: string | null }) => state.userId);
  const userData = useUser((state) => state.userData);
  const setTenant = useUser(
    (state: { setTenant: (tenant: any) => void }) => state.setTenant
  );
  const setUserId = useUser(
    (state: { setUserId: (userId: string | null) => void }) => state.setUserId
  );

  const getTenant = async () => {
    if (!userData?.tenantId) return;
    const { data } = await apiClient(
      `api/v1/tenant/${userData?.tenantId}`,
      "GET",
      {}
    );
    setTenant(data);
  };

  useEffect(() => {
    if (toastData?.show) {
      setTimeout(() => {
        setToastData({ warning: false, message: null, show: false });
      }, 5000);
    }
  }, [toastData?.show]);

  useEffect(() => {
    setUserId(userIdinLocalStorage);
  }, [userIdinLocalStorage]);

  useEffect(() => {
    getTenant();
  }, [userData?.tenantId]);

  useEffect(() => {
    setInterceptor(globalHandler);
  }, []);

  return (
    <Suspense fallback={<ErrorFallback />}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <QueryClientProvider client={queryClient}>
          {toastData?.show && (
            <ToastMessage
              warning={toastData?.warning || false}
              message={toastData?.message}
              showNotif={true}
            />
          )}
          {userId || userIdinLocalStorage ? (
            <AuthenticatedPage />
          ) : (
            <UnAuthenticatedPage />
          )}
        </QueryClientProvider>
      </ErrorBoundary>
    </Suspense>
  );
}

export default App;
