const SignWrapper = ({ children, paddingXClass = "px-[1.25rem]" }: any) => {
  return (
    <div className="flex relative bg-[url('/assets/image/login-background-new.svg')] bg-local bg-no-repeat bg-center bg-cover">
      <div className="z-[1] flex flex-col items-center w-full h-[100vh] overflow-y-auto">
        <img
          src="/assets/image/provenant_logo_white.svg"
          className="w-[14.25rem] my-[6rem]"
          alt="logo-provenant"
        />
        <div
          className={`flex flex-col gap-[1rem] w-[35rem] h-fit py-[2.5rem] rounded-[1.25rem] bg-n-000 mb-[4rem] ${paddingXClass}`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default SignWrapper;
