import { getHeaderColumnId, getColumnId } from "../design-system/table/utils";

const focusOnElement = (id: string) => {
  // console.log("focus: " + id);
  if (!document?.getElementById(id)) return;
  (document.activeElement as HTMLElement)?.blur();
  window.setTimeout(() => {
    document.getElementById(id)?.focus();
  }, 0);
};

const tryFocusOnElement = (ids: string[], onFail?: () => void) => {
  let index = 0;
  let flag = false;

  while (!flag && index < ids?.length) {
    if (
      document?.getElementById(ids[index]) &&
      !document?.getElementById(ids[index])?.hasAttribute("disabled")
    ) {
      focusOnElement(ids[index]);
      flag = true;
    }
    index++;
  }
  if (!flag) onFail ? onFail() : focusOnElement("header-user-name");
};

const focusOnChildElement = (parentId: string, childId: string) => {
  const childElement = document
    .getElementById(parentId)
    ?.querySelector<HTMLElement>(`#${childId}`);
  if (!childElement) return;
  window.setTimeout(() => {
    (document.activeElement as HTMLElement)?.blur();
    childElement?.focus();
  }, 0);
};

const clickOnChildElement = (parentId: string, childId: string) => {
  const childElement = document
    .getElementById(parentId)
    ?.querySelector<HTMLElement>(`#${childId}`);
  if (!childElement) return;
  window.setTimeout(() => {
    (document.activeElement as HTMLElement)?.blur();
    childElement?.click();
  }, 0);
};

const getFieldId = (field: any) => {
  const { key, parentKey } = field;
  const fieldKey = parentKey ? `${parentKey}+${key}` : key;
  const fieldId = field?.id || fieldKey;
  return fieldId;
};

const onTabField = (
  index: number,
  listField: any[],
  isNext: boolean,
  onBreak?: () => void
) => {
  if (isNext && index >= listField?.length - 1) return; // onTab
  if (!isNext && index <= 0) return; // onShiftTab
  let newIndex = index + (isNext ? 1 : -1);
  let isAvailable = true;
  while (listField[newIndex]?.disabled) {
    newIndex += isNext ? 1 : -1;
    if (newIndex < 0 || newIndex >= listField?.length - 1) {
      isAvailable = false;
      onBreak && onBreak();
      break;
    }
  }
  if (!isAvailable) return;
  const newFieldId = getFieldId(listField[newIndex]);
  focusOnElement(newFieldId);
};

const onTabCustomField = (
  key: string,
  listField: any[],
  isNext: boolean,
  onBreak?: () => void
) => {
  const index = listField?.findIndex((field) => field?.key === key);
  onTabField(index, listField, isNext, onBreak);
};

const dropdownKeyList = [
  "Tab",
  "ShiftTab",
  "ArrowDown",
  "Space",
  "onEnter",
  "Escape",
];

const onKeyPressDropdownTrigger = (
  e: React.KeyboardEvent,
  id: string,
  isDropdownOpen: boolean,
  openDropdown: () => void,
  closeDropdown: () => void,
  isDisabled: boolean,
  keyFunctions: {
    onTab?: () => void;
    onShiftTab?: () => void;
    onArrowDown?: () => void;
    onSpace?: () => void;
    onEnter?: () => void;
    onEscape?: () => void;
  },
  keys: string[] = dropdownKeyList
) => {
  e.stopPropagation();
  if (e?.key === "Tab") {
    e.preventDefault();
    if (isDropdownOpen) closeDropdown();
    if (keys?.includes("ShiftTab") && e?.shiftKey) {
      keyFunctions?.onShiftTab && keyFunctions.onShiftTab();
    } else if (keys?.includes("Tab")) {
      keyFunctions?.onTab && keyFunctions.onTab();
    }
  }

  if (isDisabled) return;

  // open dropdown
  if (keys?.includes("ArrowDown") && e?.key === "ArrowDown") {
    e.preventDefault();
    if (!isDropdownOpen) openDropdown();
    keyFunctions?.onArrowDown && keyFunctions.onArrowDown();
  }
  if (keys?.includes("Space") && e?.key === " ") {
    e.preventDefault();
    if (!isDropdownOpen) openDropdown();
    keyFunctions?.onSpace && keyFunctions.onSpace();
  }
  if (keys?.includes("onEnter") && e?.key === "Enter") {
    e.preventDefault();
    if (!isDropdownOpen) openDropdown();
    keyFunctions?.onEnter && keyFunctions.onEnter();
  }

  // close dropdown
  if (keys?.includes("Escape") && e?.key === "Escape") {
    e.preventDefault();
    if (isDropdownOpen) {
      closeDropdown();
      focusOnElement(id);
    }
    keyFunctions?.onEscape && keyFunctions.onEscape();
  }
};

const tabProps = (prevId: string | string[], nextId: string | string[]) => ({
  onShiftTab: () =>
    Array.isArray(prevId) ? tryFocusOnElement(prevId) : focusOnElement(prevId),
  onTab: () =>
    Array.isArray(nextId) ? tryFocusOnElement(nextId) : focusOnElement(nextId),
});

const setLastIdsForTablePage = (
  setLastIds: (val: string[]) => void,
  list: any[],
  tableId: string,
  headers: any[],
  fallbackId: string,
  usePagination: boolean = false
) => {
  const index = headers?.length - 1;
  const headerId = getHeaderColumnId(tableId, headers, index);
  const ids = [headerId, "clear-button", fallbackId];

  if (list?.length === 0) {
    setLastIds(ids);
  } else {
    setLastIds([
      ...(usePagination ? ["next-page-button", "previous-page-button"] : []),
      getColumnId(tableId, headers, list?.length - 1, index),
      ...ids,
    ]);
  }
};

export {
  focusOnElement,
  tryFocusOnElement,
  focusOnChildElement,
  clickOnChildElement,
  getFieldId,
  onTabField,
  onTabCustomField,
  dropdownKeyList,
  onKeyPressDropdownTrigger,
  tabProps,
  setLastIdsForTablePage,
};
