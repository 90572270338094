// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radio:focus {
  outline: none;
}

.radio:checked {
  display: none;
}

.radio:checked + label {
  position: relative;
  min-width: 20px;
  min-height: 20px;
  padding-left: 20px;
  display: inline-block;
}

.radio:checked + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  min-width: 20px;
  min-height: 20px;
  border: 2px solid var(--b-400);
  border-radius: 50%;
  background: var(--b-400);
}

.radio:checked + label:after {
  content: "";
  min-width: 8px;
  min-height: 8px;
  background: var(--n-000);
  position: absolute;
  top: 6px;
  left: 6px;
  border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/design-system/RadioButton.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,qBAAA;AACF;;AACA;EACE,WAAA;EACA,kBAAA;EACA,OAAA;EACA,MAAA;EACA,eAAA;EACA,gBAAA;EACA,8BAAA;EACA,kBAAA;EACA,wBAAA;AAEF;;AACA;EACE,WAAA;EACA,cAAA;EACA,eAAA;EACA,wBAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,kBAAA;AAEF","sourcesContent":[".radio:focus {\n  outline: none;\n}\n\n.radio:checked {\n  display: none;\n}\n\n.radio:checked + label {\n  position: relative;\n  min-width: 20px;\n  min-height: 20px;\n  padding-left: 20px;\n  display: inline-block;\n}\n.radio:checked + label:before {\n  content: \"\";\n  position: absolute;\n  left: 0;\n  top: 0;\n  min-width: 20px;\n  min-height: 20px;\n  border: 2px solid var(--b-400);\n  border-radius: 50%;\n  background: var(--b-400);\n}\n\n.radio:checked + label:after {\n  content: \"\";\n  min-width: 8px;\n  min-height: 8px;\n  background: var(--n-000);\n  position: absolute;\n  top: 6px;\n  left: 6px;\n  border-radius: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
