import { Redirect, Route, Switch } from "react-router";
import { BrowserRouter as Router, useLocation } from "react-router-dom";

import Login from "../pages/unauthenticated/Login";
import Register from "../pages/unauthenticated/Register";
import ResetPassword from "./unauthenticated/ResetPassword";
import ForgotPassword from "./unauthenticated/ForgotPassword";

const UnAuthenticatedPage = () => {
  const RedirectHome = () => {
    const { pathname } = useLocation();

    if (!pathname || pathname === "/" || !pathname?.includes("login"))
      return <Redirect to="/login" />;
    return <></>;
  };

  return (
    <Router>
      <Switch>
        <Route exact path={["/login"]} component={Login} />
        <Route exact path={["/register"]} component={Register} />
        <Route exact path={["/reset-password"]} component={ResetPassword} />
        <Route exact path={["/forgot-password"]} component={ForgotPassword} />
        <RedirectHome />
      </Switch>
    </Router>
  );
};

export default UnAuthenticatedPage;
